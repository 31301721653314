export function animationBlockShow() {
    let wScrollCurrent = $(window).width();
    if (wScrollCurrent >= 1200) {
        $('.animation_block').each(function() {
            let $this = $(this),
                bottomObject = $this.offset().top + 150,
                bottomWindow = $(window).scrollTop() + $(window).height();

            if (bottomObject < bottomWindow) {
                if (!$this.hasClass('animation_block--show')) {
                    $this.addClass("animation_block--show")
                }
            }
        })
    }
}