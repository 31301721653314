import "bootstrap";

//Block
import {menuToggle} from "./blocks/menu_toggle/menu_toggle"
import {desktopMenu} from "./blocks/desktop_menu/desktop_menu"
import {mobileMenu} from "./blocks/mobile_menu/mobile_menu"
import {slider} from "./blocks/slider/slider"
import {animationBlockShow} from "./blocks/animation_block/animation_block"
import {createCompany} from "./blocks/form/create_company"

menuToggle();
mobileMenu();
slider();
createCompany();
desktopMenu();

$(window).scroll(animationBlockShow);