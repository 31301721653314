export function createCompany() {

    $("body").on('click', '.create_company', function () {

        $('form.form').css('opacity', 0.3);
        $('form.form #preloader').show();
        $('form.form .col-12').removeClass('has-danger');
        $('form.form .col-12 .form__control_feedback').text("");

        var array = $("form.form").serialize();

        $.ajax({
            url: "/company/create",
            type: "POST",
            data: array,
            dataType: 'JSON',
            cache: false,
            success: function (response) {

                $('form.form').css('opacity', '1');
                $('form.form #preloader').hide();

                if (response.code === 200) {

                    $('form.form').hide();

                    $('form.form')[0].reset();

                    $('.warning-create-company').removeClass('d-none');

                } else {

                    if (response.message.length > 0) {

                        for (var i = 0; i < response.message.length; i++) {
                            $('form.form .feedback-' + response.message[i].field).addClass('has-danger');
                            $('form.form .feedback-' + response.message[i].field + ' .form__control_feedback').text(response.message[i].message);
                        }

                    }

                }
            }
        });

        return false;
    });

}