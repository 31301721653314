import "slick-carousel";

export function slider() {
    $('.slider_content').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        dots: true,
        prevArrow: false,
        nextArrow: false,
        responsive: [
            {
                breakpoint: 10000,
                settings: 'unslick'

            },
            {
                breakpoint: 768,
                settings: {
                    centerMode: true,
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1
                }
            }
        ]
    });
}