export function desktopMenu() {

    $('.click-scroll-participate').click(function(){

        $('.menu_toggle.js--menu_toggle').removeClass('menu_toggle--active');
        $('.mobile_menu.mobile_menu--show').removeClass('mobile_menu--show');
        $('body').removeClass('body--overflow');

        $('html,body').animate({
            scrollTop: $(".application").offset().top
        }, 2000);

    });

    $('.click-scroll-desc').click(function(){

        $('.menu_toggle.js--menu_toggle').removeClass('menu_toggle--active');
        $('.mobile_menu.mobile_menu--show').removeClass('mobile_menu--show');
        $('body').removeClass('body--overflow');

        $('html,body').animate({
            scrollTop: $(".desc_section").offset().top
        }, 2000);

    });

    $('.click-scroll-capabilities').click(function(){

        $('.menu_toggle.js--menu_toggle').removeClass('menu_toggle--active');
        $('.mobile_menu.mobile_menu--show').removeClass('mobile_menu--show');
        $('body').removeClass('body--overflow');

        $('html,body').animate({
            scrollTop: $(".capabilities").offset().top
        }, 2000);

    });

}